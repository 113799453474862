import { IForm } from '@/base-ui/form'

export const searchFormConfig: IForm = {
  formItems: [
    {
      field: 'oddnumber',
      type: 'input',
      label: '运单号:',
      placeholder: '请输入运单号'
    },
    {
      field: 'consignee',
      type: 'input',
      label: '收货人:',
      placeholder: '请输入收货人'
    },
    {
      field: 'consignor',
      type: 'input',
      label: '发货人:',
      placeholder: '请输入发货人'
    },
    {
      field: 'recoverystate',
      type: 'select',
      label: '回收状态:',
      placeholder: '请选择回收状态',
      options: [
        { title: '已回收', value: '已回收' },
        { title: '未回收', value: '未回收' }
      ]
    },
    {
      field: 'issuestate',
      type: 'select',
      label: '发放状态:',
      placeholder: '请选择发放状态',
      options: [
        { title: '已发放', value: '已发放' },
        { title: '未发放', value: '未发放' }
      ]
    },
    {
      field: 'poststate',
      type: 'select',
      label: '寄出状态:',
      placeholder: '请选择寄出状态',
      options: [
        { title: '已寄出', value: '已寄出' },
        { title: '未寄出', value: '未寄出' }
      ]
    },
    {
      field: 'createAt',
      type: 'datepicker',
      label: '开单时间:',
      otherOptions: {
        startPlaceholder: '开始时间',
        endPlaceholder: '结束时间',
        type: 'daterange'
      }
    }
  ]
}
